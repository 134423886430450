import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/style.scss";
import Vant from "vant";
import "vant/lib/index.css";
import tool from "./assets/scripts/tool";
import wechat from "./assets/scripts/wechat";
import utils from "@/assets/scripts/utils";
import moment from "moment";
import api from "./api/install";
import { dream, sheng, chaos } from "@/assets/scripts/account.js";

Vue.prototype.tool = tool;
Vue.prototype.$wechat = wechat;
Vue.prototype.$utils = utils;
Vue.prototype.$moment = moment;

Vue.config.productionTip = false;

Vue.use(api).use(Vant);

window._AMapSecurityConfig = {
  securityJsCode: chaos.code, //code | 账号dream,sheng,chaos
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
