<template>
  <div class="container">
    <div class="home">
      <div class="banner">
        <van-image :src="require('@/assets/imgs/banner_index.png')" />
      </div>
      <div class="formFliedOuter">
        <div class="formFliedHrader">
          <div class="helpBtn">
            <van-button type="info" @click="openOverlay">?</van-button>
          </div>
          <div class="fliedSet">
            <p>
              <span>请输入验证码</span>
              <span>Enter verification code</span>
            </p>
          </div>
        </div>
        <div class="formFliedInner">
          <van-form
            @submit="onSubmit"
            input-align="center"
            label-align="center"
          >
            <van-field
              v-if="codeShow"
              type="number"
              maxlength="13"
              v-model="code"
              name="code"
              placeholder="请输入二维码下方的身份码"
              :rules="[{ required: true, message: '' }]"
            />
            <van-field
              type="number"
              minlength="4"
              v-model="verify"
              name="verify"
              placeholder="请输入验证码"
              :rules="[{ required: true, message: '' }]"
            />
            <div style="margin: 0.32rem 0.26rem">
              <van-button
                v-if="subState"
                round
                block
                type="info"
                native-type="submit"
                :icon="require('@/assets/imgs/submit_icon.svg')"
              >
                验证
              </van-button>
              <van-button
                v-else
                block
                type="info"
                loading
                loading-type="spinner"
                loading-text="验证中..."
              />
            </div>
          </van-form>
        </div>
      </div>
      <color-border
        icon="addcare.png"
        multiple="true"
        title="关注公众号"
        more="获取更多图书增值服务"
        color="rgba(255, 144, 63, 1)"
      >
        <div class="footerQRCode">
          <div class="QRCode">
            <div class="code">
              <van-image :src="require('@/assets/imgs/newQRCode.jpg')" />
            </div>
            <div class="name">
              <div class="sanjiaoIcon"></div>
              <div class="content">一起同学</div>
            </div>
          </div>
          <!-- <div class="QRCode">
            <div class="code">
              <van-image :src="require('@/assets/imgs/QRCode2.png')" />
            </div>
            <div class="name">
              <div class="sanjiaoIcon"></div>
              <div class="content">53同学(初中)</div>
            </div>
          </div> -->
        </div>
      </color-border>

      <van-overlay :show="show" @click="closeOverlay" z-index="20">
        <div :class="{ wrapper: !isLandscape, wrapper_land: isLandscape }">
          <div class="fengdi">
            <van-image :src="require('@/assets/imgs/fengdi.png')" />
          </div>
          <div class="juxing">
            <van-image :src="require('@/assets/imgs/juxing.png')" />
          </div>
          <div class="jiantouxia">
            <van-image :src="require('@/assets/imgs/jiantouxia.png')" />
          </div>
          <div class="yanzhengma">
            <van-image :src="require('@/assets/imgs/yanzhengma.png')" />
          </div>
        </div>
      </van-overlay>
    </div>
    <!-- <van-loading v-else class="wxinfo" type="spinner" color="#fff">微信授权中...</van-loading> -->
    <div id="amap-container"></div>
  </div>
</template>

<script>
import qs from "qs";
import ColorBorder from "@/components/ColorBorder.vue";
import { Toast, Dialog } from "vant";
import AMapLoader from "@amap/amap-jsapi-loader";
import { dream, sheng, chaos } from "@/assets/scripts/account.js";

export default {
  name: "Home",
  components: {
    ColorBorder,
  },
  data() {
    return {
      isLandscape: false,
      show: false,
      subState: true,
      loading: false,
      identify: "",
      verify: "",
      code: this.$route.query.id, //3282308232160
      codeShow: this.$route.query.id ? false : true, //是否有值，有值扫码, 无值公众号 是否显示输入框
      source: this.$route.query.source == null ? 11 : this.$route.query.source, //来源 1H5 2公众号 3手机官网 4PC官网
      bookInfo: null, //图书信息
      codeInfo: null, //码的信息
      codeType: "", //类型 1多次 2首次 3未采集或未入库出库信息 4没查到
      isWx: this.$wechat.isWeixin(), //是否再微信环境下
      ip: "",
      //23.9.1------
      href: window.location.origin,
      query: window.location.search,
      userinfo: sessionStorage.getItem("userinfo"),
      token: "",
      //
      map: null,
      AMapLoader: {
        key: chaos.key, //key | 账号dream,sheng,chaos
        version: "2.0",
        plugins: ["AMap.Geolocation", "AMap.Geocoder"],
      },
    };
  },
  mounted() {
    this.renderResize();
    // 监听resize方法
    window.addEventListener("resize", this.renderResize, false);

    //拿IP
    fetch("https://api.ipify.org?format=json")
      .then((x) => x.json())
      .then(({ ip }) => {
        this.ip = ip;
        //console.log(this.ip);
      });

    //高德
    let that = this;
    AMapLoader.load(this.AMapLoader).then((AMap) => {
      this.map = new AMap.Map("amap-container");
      const Geocoder = new AMap.Geocoder();
      this.map.plugin("AMap.Geolocation", () => {
        var geolocation = new AMap.Geolocation({
          enableHighAccuracy: true,
          timeout: 10000,
        });
        geolocation.getCurrentPosition(function (status, result) {
          if (status == "complete") {
            const {
              position: { lng, lat },
            } = result;
            Geocoder.getAddress([lng, lat], (status, res) => {
              if (status === "complete" && res.info === "OK") {
                let { addressComponent, formattedAddress } = res.regeocode;
                that.savePosition(
                  addressComponent.province,
                  addressComponent.city,
                  addressComponent.district,
                  formattedAddress,
                  lat,
                  lng
                );
                //Toast(formattedAddress);
                console.log("地址:" + formattedAddress);
                console.log(addressComponent);
              } else {
                Dialog.alert({
                  title: "定位失败",
                  message: "未获取到您的位置信息！请刷新",
                  confirmButtonText: "刷新",
                }).then(() => {
                  //刷新页面
                  window.location.reload();
                });
              }
            });
          }
        });
      });
    });
    //是否微信环境
    // if (this.isWx) {
    //   //使用微信能力拿取用户定位信息
    //   this.$wechat.getLocation().then((res) => {
    //     this.$api.code
    //       .getWxAddres({
    //         location: res.latitude + "," + res.longitude,
    //       })
    //       .then((r) => {
    //         let { Tag, Data } = r;
    //         if (Tag == 1 && Data.message == "query ok") {
    //           let { result } = Data;
    //           console.log(result);
    //           this.savePosition(
    //             result.address_component.province,
    //             result.address_component.city,
    //             result.address_component.district,
    //             result.address,
    //             res.latitude,
    //             res.longitude
    //           );
    //         } else {
    //           console.log("定位失败");
    //           Dialog.alert({
    //             title: "定位失败",
    //             message: "未获取到您的位置信息！请刷新",
    //             confirmButtonText: "刷新",
    //           }).then(() => {
    //             //刷新页面
    //             window.location.reload();
    //           });
    //         }
    //       });
    //   });
    // } else {
    //   this.getLocation();
    // }
  },
  beforeDestroy() {
    // 移除监听
    window.removeEventListener("resize", this.renderResize, false);
  },
  methods: {
    //查询
    onSubmit(e) {
      this.subState = false;
      if (!isNaN(this.verify) && this.verify.length < 4) {
        Toast("请输入正确的验证码");
        this.subState = true;
        return false;
      }
      // if (this.province == "") {
      //   Dialog.alert({
      //     title: "拒绝查询",
      //     message: "未获取到您的定位！",
      //     confirmButtonText: "刷新",
      //   }).then(() => {
      //     location.reload();
      //   });
      //   return false;
      // }
      let params = {
        Code1: this.code,
        VerifyCode: this.verify,
      };
      this.$api.code.GetCode(qs.stringify(params), this.token).then((res) => {
        let { Data, Tag, Message } = res;
        if (Tag == 1) {
          let { bookJson, codeJson, codeType } = Data;
          //取图书信息
          this.bookInfo = bookJson;
          //取类型
          this.codeType = codeType;
          //取码信息
          this.codeInfo = codeJson;

          let timestamp = new Date().getTime();

          this.$router.push({
            name: "Detail",
            params: {
              code: this.code,
              verify: this.verify,
              source: this.source,
              bookInfo: this.bookInfo,
              codeType: this.codeType,
              codeInfo: this.codeInfo,
              ip: this.ip,
            },
            query: {
              time: timestamp,
              code: this.code,
            },
          });
        } else {
          this.subState = true;
          this.$dialog.alert({
            title: "错误",
            message: Message,
          });
        }
      });
    },
    //注册腾讯获取KEY
    // getLocation() {
    //   let geolocation = new window.qq.maps.Geolocation(
    //     "VBHBZ-NV46J-Y6EFK-F6A6M-255DF-JKFQZ",
    //     "mycode",
    //     [{ timeout: 10, failTipFlag: true }]
    //   );
    //   //getLocation(成功回调,错误回调)
    //   geolocation.getLocation(this.showPosition, this.errorPosition);
    // },
    //获取位置成功
    // showPosition(position) {
    //   console.log("定位成功");
    //   console.log(position);
    //   this.savePosition(
    //     position.province,
    //     position.city,
    //     position.district,
    //     position.addr,
    //     position.lat,
    //     position.lng
    //   );
    // },
    //storage保存位置信息
    savePosition(pro, city, dis, add, lat, lng) {
      this.loading = true;
      this.province = pro;
      let info = {
        province: pro,
        city: city,
        district: dis,
        address: add,
        lat: lat,
        lng: lng,
      };
      sessionStorage.setItem("addr", JSON.stringify(info));
    },

    openOverlay() {
      this.show = true;
    },
    closeOverlay() {
      this.show = false;
    },
    //判断当前横竖屏
    renderResize() {
      // 判断横竖屏
      let width = document.documentElement.clientWidth;
      let height = document.documentElement.clientHeight;
      if (width > height) {
        this.isLandscape = true;
      } else {
        this.isLandscape = false;
      }
      // 做页面适配
      // 注意：renderResize方法执行时虚拟dom尚未渲染挂载，如果要操作vue实例，最好在this.$nextTick()里进行。
    },
  },
};
</script>

<style scoped lang="scss">
#amap-container {
  width: 100%;
  height: 1px;
  visibility: hidden;
}
.container {
  height: 100%;
}

.wxinfo {
  padding-top: 20px;
  height: 100%;
  line-height: 100px;
  text-align: center;
  background: #48a8e7;
}

.home {
  padding-bottom: 60px;

  .banner {
    margin: 18px -8px 0 30px;
  }

  .formFliedOuter {
    margin: 30px 40px;
    padding: 26px;
    border-radius: 26px;
    background-color: rgba(255, 144, 63, 1);
    min-height: 180px;

    .formFliedHrader {
      height: 70px;
      padding-left: 40px;
      display: flex;
      position: relative;

      .helpBtn .van-button {
        border: none;
        background-color: #fff;
        padding: 22px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        color: rgba(255, 144, 63, 1);
        font-weight: 600;
        font-size: 32px;
      }

      .fliedSet {
        height: 280px;
        background: rgba(92, 109, 201, 1);
        border-radius: 26px;
        position: absolute;
        top: -50px;
        right: -26px;
        padding-top: 45px;
        padding-right: 65px;
        padding-left: 10px;

        span:first-child {
          display: inline-block;
          color: #fff;
          font-size: 36px;
          font-family: "FZCuYuan-M03S";
          font-weight: 600;
          letter-spacing: 1px;
          margin-right: 15px;
        }

        span:last-child {
          display: inline-block;
          font-size: 28px;
          color: rgba(229, 229, 229, 1);
        }
      }

      .fliedSet::before {
        content: "";
        display: block;
        background: rgba(92, 109, 201, 1);
        // background: #fff;
        width: 100px;
        height: 180px;
        clip-path: polygon(100% 0, 0 100%, 100% 100%);
        position: absolute;
        left: -98px;
        top: 14px;
      }
    }
  }

  .footerQRCode {
    display: flex;
    justify-content: space-around;

    .QRCode {
      display: flex;
      flex-direction: column;
      align-items: center;

      .code {
        width: 200px;
      }

      .name {
        height: 30px;
        line-height: 30px;
        background: #fff;
        border-radius: 50px;
        padding: 15px 25px;
        margin-top: 20px;
        display: flex;

        .icon {
          width: 22px;
          height: 22px;
          background: rgba(92, 109, 201, 1);
          align-self: center;
          margin-right: 10px;
          clip-path: polygon(0 0, 100% 50%, 0 100%);
        }

        .content {
          align-self: center;
          color: rgba(255, 144, 63, 1);
        }
      }
    }
  }

  .wrapper {
    position: absolute;
    width: 100%;
    top: 34%;
    height: 430px;

    .fengdi {
      position: absolute;
      left: 180px;
      width: 150px;
    }

    .juxing {
      position: absolute;
      left: 236px;
      top: 165px;
      width: 150px;
    }

    .jiantouxia {
      position: absolute;
      left: 256px;
      top: 200px;
      width: 15px;
    }

    .yanzhengma {
      position: absolute;
      left: 176px;
      top: 225px;
      width: 155px;
    }
  }

  .wrapper_land {
    position: absolute;
    width: 100%;
    top: 14%;
    height: 430px;

    .fengdi {
      position: absolute;
      left: 380px;
      width: 150px;
    }

    .juxing {
      position: absolute;
      left: 436px;
      top: 165px;
      width: 150px;
    }

    .jiantouxia {
      position: absolute;
      left: 456px;
      top: 200px;
      width: 15px;
    }

    .yanzhengma {
      position: absolute;
      left: 376px;
      top: 225px;
      width: 155px;
    }
  }
}
</style>
