export const dream = {
  key: "240e2593a45120592a96f4e0fa88509b",
  code: "901fed8c13f60113672d7083ecb2e9b3",
};
export const sheng = {
  key: "fd3aabb7e5d4536423a4692771f3c01d",
  code: "53e0fb34acc921b96fb1df864961a4c5",
};
export const chaos = {
  key: "2319529527c12fca9b86404449d0488d",
  code: "f523dd27b57ceb8ad22dc4a8f71e43c1",
};
