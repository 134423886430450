import api from "../index";
import urls from "./url";

export default {
  //admin------------------------------
  //追溯/获取信息
  getCodeAll(params) {
    return api.post(urls.getCodeAll, params);
  },

  //user用户------------------------------
  //获取码与图书信息
  GetCode(params, head) {
    return api.post(urls.getCode, params, head);
  },
  //保存用户信息
  SaveUserInfo(params, head) {
    return api.post(urls.SaveInfo, params, head);
  },
  //保存盗版信息
  SaveUserPirate(params, head) {
    return api.post(urls.SavePirate, params, head);
  },

  //Wx----------------------------------------
  //获取微信配置=>assets>wechat.js
  //获取微信用户基础信息
  getWxInfo(params) {
    return api.post(urls.wxInfo, params);
  },
  //微信公众号授权登录
  getWxLogin(params) {
    return api.post(urls.wxLogin, params);
  },
  //经纬度换取地址
  getWxAddres(params) {
    return api.get(urls.wxAddress, params);
  },
  //发送红包
  sendRedPacket(params) {
    return api.post(urls.wxRedPack, params);
  },

  //上传OSS--------------------------------------
  //保存图片OSS
  saveFileOSS(params) {
    return api.post(urls.saveFile, params);
  },

  //Lottery抽奖-----------------------------------
  //保存抽奖
  saveLotterycj(params, head) {
    return api.post(urls.saveLottery, params, head);
  },
  //获取抽奖
  getLotteryResult(params, head) {
    return api.post(urls.getLottery, params, head);
  },
  //获取抽奖列表
  getLotteryList(params, head) {
    return api.post(urls.getLotteryList, params, head);
  },
  //保存收货地址
  saveAddress(params) {
    return api.post(urls.saveAddress, params);
  },

  //sign签到---------------------------------------
  //签到
  signIn(params) {
    return api.post(urls.doSign, params);
  },
  //获取签单信息
  getSignInfo(params) {
    return api.post(urls.getSignInfo, params);
  },

  //Integral积分---------------------------------------
  //获取积分商品列表
  getIntegralList(params) {
    return api.post(urls.getIntegralList, params);
  },
  //获取积分兑换记录列表
  getIntegralRecordList(params) {
    return api.post(urls.getIntegralRecordList, params);
  },
  //获取积分出入记录列表
  getIntegralDetailList(params) {
    return api.post(urls.getIntegralDetailList, params);
  },
};
