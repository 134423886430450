export default {
  //转url
  urlEncode(str) {
    str = (str + "").toString();
    return encodeURIComponent(str)
      .replace(/!/g, "%21")
      .replace(/'/g, "%27")
      .replace(/\(/g, "%28")
      .replace(/\)/g, "%29")
      .replace(/\*/g, "%2A")
      .replace(/%20/g, "+");
  },
  //获取随机数
  getRandom(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  },
};
