<template>
    <div class="box">
        <fieldset :style="{ borderColor: color }">
            <legend>
                <div class="icon">
                    <van-image v-if="icon" :src="require('@/assets/imgs/' + icon)" />
                    <!-- <van-image :src="require('@/assets/imgs/addcare.png')" /> -->
                </div>
                <div v-if="multiple" class="headerMulti">
                    <div class="title1" :style="{ backgroundColor: title ? 'rgba(252, 231, 76, 1)' : 'transparent' }">
                        {{ title }}
                    </div>
                    <div class="title2"
                        style="background: linear-gradient(90deg, rgba(255, 144, 63, 1) 0%, rgba(253, 214, 73, 0.2) 73.3%, rgba(252, 231, 76, 0) 100%);">
                        {{ more }}
                    </div>
                </div>
                <div v-else class="headerOnly">
                    <div class="title1" :style="{ backgroundColor: title ? 'rgba(252, 231, 76, 1)' : 'transparent' }">
                        <span>
                            {{ title }}
                        </span>
                    </div>
                    <div class="title2"
                        :style="{ background: title ? 'linear-gradient(90deg, rgba(255, 144, 63, 1) 0%, rgba(253, 214, 73, 0.2) 73.3%, rgba(252, 231, 76, 0) 100%)' : 'transparent' }">
                    </div>
                </div>
            </legend>
            <slot></slot>
        </fieldset>
    </div>
</template>
  
<script>
export default {
    name: 'ColorBorder',
    props: ['multiple', 'icon', 'title', 'more', 'color']
}
</script>
  
<style scoped lang="scss">
.box {
    margin: 30px 40px;

    fieldset {
        border: 8px solid;
        padding: 20px 35px 35px;
        border-radius: 20px;
    }

    legend {
        width: 490px;
        height: 140px;
        font-size: 28px;
        padding: 0 10px;
        margin-left: -46px;
        padding: 20px 15px;
        display: flex;
        background-color: rgba(242, 242, 242, 1);

        .icon {
            width: 85px;
            height: 85px;
            align-items: center;
            align-self: center;
        }

        .headerMulti {
            width: 370px;
            height: 130px;
            margin-left: 20px;
            align-items: center;
            align-self: center;
            font-family: 'FZDaHei-B02S';
            font-size: 32px;
            color: #000;
            font-weight: bolder;

            .title1 {
                display: inline-block;
                height: 65px;
                line-height: 65px;
                padding-left: 12px;
                padding-right: 20px;
            }

            .title2 {
                height: 65px;
                line-height: 65px;
                padding-left: 12px;
            }
        }

        .headerOnly {
            width: 370px;
            height: 130px;
            margin-left: 20px;
            align-items: center;
            align-self: center;
            font-family: 'FZDaHei-B02S';
            font-size: 36px;
            color: #000;
            font-weight: bolder;
            position: relative;

            // .title {
            //     position: absolute;
            //     top: 50%;
            //     transform: translate(0, -50%);
            //     padding-left: 15px;
            // }

            .title1 {
                height: 65px;
                line-height: 65px;
                display: inline-block;
                padding-left: 12px;
                padding-right: 20px;
                position: relative;

                span {
                    position: relative;
                    top: 50%;
                }
            }

            .title2 {
                height: 70px;
                line-height: 70px;
            }
        }
    }
}
</style>
  