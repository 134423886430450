<template>
  <div class="detail">
    <div class="bookInfo" v-if="bookInfo">
      <div class="logo">
        <van-image :src="require('@/assets/imgs/logo.png')" fit="contain" />
      </div>
      <div class="bookInfoInner">
        <color-border color="rgba(92, 109, 201, 1)">
          <div class="bookHeader">
            <div class="bookCover">
              <van-image :src="bookInfo.Cover == ''
                ? 'https://c.cdn.quyixian.com/nocover.png'
                : bookInfo.Cover" />
            </div>
            <div class="title">
              {{ (bookInfo.NickName ? bookInfo.NickName : bookInfo.Name) || '------' }}
            </div>
          </div>
          <div class="bookDetails">
            <div class="item">
              <div class="label">
                <div class="sanjiaoIcon icon"></div>
                <div>版本：</div>
              </div>
              <div class="content">{{ bookInfo.Banben || '--' }} </div>
            </div>
            <div class="item">
              <div class="label">
                <div class="sanjiaoIcon icon"></div>
                <div>书号：</div>
              </div>
              <div class="content">{{ bookInfo.BookNumber || '--' }} </div>
            </div>
            <div class="item">
              <div class="label">
                <div class="sanjiaoIcon icon"></div>
                <div>开本：</div>
              </div>
              <div class="content">{{ bookInfo.Folio || '--' }}</div>
            </div>
            <div class="item">
              <div class="label">
                <div class="sanjiaoIcon icon"></div>
                <div>定价：</div>
              </div>
              <div class="content">{{ bookInfo.Pricing || '--' }}元</div>
            </div>
            <div class="item">
              <div class="label">
                <div class="sanjiaoIcon icon"></div>
                <div>出版社：</div>
              </div>
              <div class="content" v-html="bookInfo.Publishing"> </div>
            </div>
          </div>
        </color-border>
      </div>
    </div>
    <color-border icon="verify.png" title="验证结果" color="rgba(255, 144, 63, 1)">
      <div class="verify_result">
        <div v-if="getStatusCode(isPirate, codeType, codeCount) === 'first'" class="verify_result_body">
          <!-- <div v-if="(codeType == 1 || codeType == 3) && codeCount == 0" class="verify_result_body"> -->
          <div class="header">
            <div class="get_result">此图书首次被验证！</div>
          </div>
          <div class="content_div">
            <div class="content">首次查询时间：{{ $moment(currentTime).format("YYYY年MM月DD日HH时mm分") }}</div>
            <div class="content">首次查询地址：{{ currentAddress }}</div>
          </div>
        </div>
        <div v-if="getStatusCode(isPirate, codeType, codeCount) === 'multiple'" class="verify_result_body">
          <div class="header">
            <div class="get_result get_result_and">警告：此图书已被验证{{ codeCount }}次！</div>
          </div>
          <div class="content_div">
            <div class="content">首次查询时间：{{ $moment(firstTime).format("YYYY年MM月DD日HH时mm分") }}</div>
            <div class="content">首次查询地址：{{ firstAddress }}</div>
            <div class="content" v-if="isPirate == 0 && codeCount >= 10">该防伪码查询次数过多，谨防假冒！</div>
          </div>
        </div>
        <div v-if="getStatusCode(isPirate, codeType, codeCount) === 'pirate'" class="verify_result_body pirate">
          <div class="header">
            <div class="get_result">此书为盗版图书！</div>
          </div>
          <div class="content_div">
            <div class="content">请与我公司客服联系（电话：13812345678）</div>
            <div class="content">凡提供相关线索一经查实，我公司将给予正版图书及现金奖励！</div>
            <div class="content">首次查询时间：{{ $moment(firstTime).format("YYYY年MM月DD日HH时mm分") }}</div>
            <div class="content">首次查询地址：{{ firstAddress }}</div>
          </div>
          <div class="content last_content">
            <van-button round block type="info" native-type="submit" @click="onReportPirate">我要举报盗版</van-button>
          </div>
        </div>

      </div>
    </color-border>

    <div v-if="getStatusCode(isPirate, codeType, codeCount) === 'pitate'" class="meet_pirate">
      <div class="meet_pirate_txt"><van-image :src="require('@/assets/imgs/report.png')" />遇到盗版怎么办？</div>
      <van-button round block type="default" native-type="submit" @click="onCheckPirate">点此查看</van-button>
    </div>

    <color-border icon="tip.png" title="特别说明" color="rgba(92, 109, 201, 1)">
      <div class="instruction">
        <div class="item">
          <div class="item_box">
            <div class="img"><van-image :src="require('@/assets/imgs/finger_QRCode.png')" /></div>
            <div class="text">
              正版图书的验证二维码码会采用喷印工艺，用手触摸二维码会有凹凸不平的感觉。
            </div>
          </div>
        </div>
        <div class="item">
          <div class="item_box">
            <div class="img"><van-image :src="require('@/assets/imgs/laser.png')" /></div>
            <div class="text">
              使用激光笔照射正版图书封面或书脊，会显示和封底二维码下方的身份码相同的数字。
            </div>
          </div>
        </div>
        <div class="item">
          <div class="item_box">
            <div class="img"><van-image :src="require('@/assets/imgs/lagel_QRCode.png')" /></div>
            <div class="text">
              正版图书验证码为一书一码，且每本图书均有唯一的13位身份码及4位验证码。
            </div>
          </div>
        </div>
      </div>
    </color-border>

    <div class="footer_banner" @click="onKnowPirate">
      <van-image :src="require('@/assets/imgs/footer_banner.png')" />
    </div>
  </div>
</template>
  
<script>
import qs from "qs";
import { Toast, Dialog } from "vant";
import ColorBorder from '@/components/ColorBorder.vue'

export default {
  name: 'Detail',
  props: {
    msg: String
  },
  components: {
    ColorBorder
  },
  data() {
    return {
      isLandscape: false,
      count: -1,
      loading: false,
      code: this.$route.query.code, //码
      verify: this.$route.params.verify, //验证码
      source: this.$route.params.source, //来源
      bookInfo: this.$route.params.bookInfo, //图书信息
      codeInfo: this.$route.params.codeInfo, //码信息
      codeType:
        this.$route.params.codeType == null ? 4 : this.$route.params.codeType, //类型  1多次 2首次 3未采集或未入库出库信息  4没查到
      ip: this.$route.params.ip,
      map: JSON.parse(sessionStorage.getItem("addr")), //定位信息
      //sohu: returnCitySN, //搜狐定位
      isPirate: 0, //是否提示盗版信息来源后台 默认0关闭
      codeCount: 0, //查询次数
      firstTime: "", //首次时间
      firstAddress: "", //首次地址
      currentAddress: "", //当前地址
      currentTime: new Date(), //当前时间

      userinfo: JSON.parse(sessionStorage.getItem("userinfo")),
      token: "",
    };
  },
  mounted() {
    this.renderResize()
    // 监听resize方法
    window.addEventListener("resize", this.renderResize, false)

    if (this.ip == null || this.ip == "") {
      fetch("https://api.ipify.org?format=json")
        .then((x) => x.json())
        .then(({ ip }) => {
          this.ip = ip;
        });
    }
    // this.token = { Authorization: "Bearer " + this.userinfo.token };

    if (this.codeInfo != null) {
      let { FirstTime, FirstAddress, Count, IsPirate, Info } = this.codeInfo;
      this.isPirate = IsPirate;
      this.firstTime = FirstTime;
      this.firstAddress = FirstAddress;
      if (Count && Count > 0) {
        this.codeCount = Count + 1;
      }
    }
    if (this.map && this.map.province != null) {
      this.currentAddress =
        this.map.address == null ? this.map.province : this.map.address;
      //console.log(this.currentAddress);
    }

    if (this.bookInfo != null) {
      let pub = this.bookInfo.Publishing;
      if (pub) {
        this.bookInfo.Publishing = pub.replace(/  /, "<br/>");
      }
    }

    //延时保存记录;
    let that = this;
    setTimeout(function () {
      if (that.map && (that.map.address == null || that.map.province == null)) {
        Dialog.alert({
          title: "拒绝查询",
          message: "获取位置信息异常！",
          confirmButtonText: "返回",
        }).then(() => {
          that.$router.push({ path: "/", query: { id: that.code } });
        });
        return false;
      } else {
        that.SaveUser();
      }
    }, 1000);
  },
  beforeDestroy() {
    // 移除监听
    window.removeEventListener("resize", this.renderResize, false)
  },
  methods: {
    SaveUser() {
      let task = null,
        taskpress = null,
        book = null,
        code2 = null;
      if (this.codeInfo != null) {
        if (this.codeInfo.TaskId) {
          task = this.codeInfo.TaskId;
        }
        if (this.codeInfo.TaskId) {
          taskpress = this.codeInfo.TaskPressId;
        }
        if (this.codeInfo.BookId) {
          book = this.codeInfo.BookId;
        }
        if (this.codeInfo.Code2) {
          code2 = this.codeInfo.Code2;
        }
      }
      let info = "";
      if (this.map && this.map.province != null) {
        info =
          "province:" +
          this.map.province +
          ",city:" +
          this.map.city +
          ",district:" +
          this.map.district +
          ",lat:" +
          this.map.lat +
          ",lng:" +
          this.map.lng;
      }
      let params = {
        CodeType: this.codeType,
        Code1: this.code,
        VerifyCode: this.verify,
        TaskId: task,
        TaskPressId: taskpress,
        BookId: book,
        Code2: code2,
        Source: this.source,
        //
        IP: this.ip,
        Address: this.map != null ? this.map.address : "",
        Province: this.map != null ? this.map.province : "",
        City: this.map != null ? this.map.city : "",
        District: this.map != null ? this.map.district : "",
        Info: info,
      };
      this.$api.code
        .SaveUserInfo(qs.stringify(params))
        .then((res) => {
          let { Data, Tag, Message } = res;
          if (Tag == 1) {
            //console.log(params);
          } else {
            this.$dialog.alert({
              title: "错误",
              message: Message,
            });
          }
        });
    },
    formatStr(str) {
      str = str.replace(/\r\n/gi, "<br/>");
      return str;
    },
    onCheckPirate() {
      window.location.replace(`https://c.quyixian.com/pirate?code=${this.code}`);
    },
    onReportPirate() {
      window.location.replace(`https://c.quyixian.com/pirateform?code=${this.code}`);
    },
    onKnowPirate() {
      window.location.replace(`https://c.quyixian.com/cont`);
    },
    getStatusCode(isPirate, type, count) {
      if (isPirate || type == 6) {
        return 'pirate';
      }
      if ((type == 2 && count != 0) || (type == 3 && count != 0)) {
        return 'multiple';
      }
      if ((type == 1 && count == 0) || (type == 3 && count == 0)) {
        return 'first';
      }

      return 'pirate';
    },
    //判断当前横竖屏
    renderResize() {
      // 判断横竖屏
      let width = document.documentElement.clientWidth
      let height = document.documentElement.clientHeight
      if (width > height) {
        this.isLandscape = true
      } else {
        this.isLandscape = false
      }
      // 做页面适配
      // 注意：renderResize方法执行时虚拟dom尚未渲染挂载，如果要操作vue实例，最好在this.$nextTick()里进行。
    },
  },
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.detail {
  padding-bottom: 10px;

  .van-image {
    display: block;
  }

  .bookInfo {
    position: relative;
    padding: 35px 0px 10px;

    .logo {
      width: 110px;
      height: 110px;
      position: absolute;
      right: 60px;
    }

    .bookInfoInner {
      margin-top: 45px;
      position: relative;

      .bookHeader {
        display: flex;

        .bookCover {
          width: 215px;
          // box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.25);
          position: absolute;
          top: -15px;
        }

        .title {
          width: 440px;
          height: 110px;
          line-height: 55px;
          font-size: 36px;
          font-weight: 600;
          margin-left: 240px;
          margin-top: -60px;
          text-align: justify;
        }
      }

      .bookDetails {
        display: flex;
        flex-wrap: wrap;
        margin-top: 78px;

        .item {
          width: 340px;
          display: flex;

          .label {
            display: flex;

            .icon {
              align-self: normal;
              transform: translate(0, 31%);
            }
          }

          .content {
            height: 70px;
            line-height: 35px;
            padding-top: 2px;
            max-width: 210px;
            word-break: break-all;
          }
        }

        .item:last-of-type {
          width: 100%;

          .content {
            height: 35px;
            line-height: 35px;
            max-width: 500px;
            word-break: break-all;
          }
        }
      }
    }

  }

  .verify_result {
    padding-bottom: 30px;

    .verify_result_body {
      .header {
        font-size: 50px;
        color: rgba(254, 71, 44, 1);
        font-weight: 600;
        text-align: center;
        margin: 10px 0 50px 0;
      }

      .content_div {
        .content {
          text-align: left;
          line-height: 50px;
          margin: 30px 0;
          font-size: 29px;

          .daili {
            display: flex;
          }
        }

        &>:not(.content:last-child)::after {
          content: '';
          height: 1px;
          border-top: 3px dashed rgba(255, 132, 80, 1);
          display: block;
          margin-top: 30px;
        }
      }
    }

  }

  .meet_pirate {
    display: flex;
    justify-content: space-between;
    margin: 70px 40px 50px;
    border: 8px solid rgba(255, 144, 63, 1);
    border-radius: 20px;
    padding: 20px;

    .meet_pirate_txt {
      display: flex;
      width: 450px;
      font-size: 40px;
      font-weight: 600;
      font-family: 'FZCuYuan-M03S';
      color: rgba(254, 71, 44, 1);

      .van-image {
        width: 56px;
        vertical-align: middle;
        margin-right: 20px;
        margin-bottom: 6px;
      }

    }

    .van-button {
      width: 130px;
      height: 60px;
      border: none;
      border-radius: 16px;
      font-size: 28px;
      font-weight: 600;
      color: rgba(92, 109, 201, 1);
      padding: 0;
      align-self: center;
      background: none;
      margin-right: 15px;
    }

  }

  .instruction {
    .item {

      .item_box {
        display: flex;
        justify-content: space-between;

        .img {
          width: 180px;
        }

        .text {
          width: 470px;
          height: 180px;
          line-height: 60px;
          text-align: justify;
          font-size: 26px;
          letter-spacing: 1px;
        }
      }
    }

    &>:not(.item:last-child)::after {
      width: 100%;
      content: '';
      height: 1px;
      border-top: 3px dashed rgba(255, 132, 80, 1);
      display: block;
      margin: 50px 0;
    }
  }

  .footer_banner {
    margin: 70px 42px;
    border-radius: 30px;
    overflow: hidden;
    box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.2);

    .van-image {
      margin-left: -36px;
      margin-right: -36px;
      margin-bottom: -25px;
    }
  }
}
</style>
  