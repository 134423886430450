const baseUrl = "https://c.api.quyixian.com/api"; //正式环境
// const baseUrl = "https://wxtestapi.quyixian.com/api"; //新的测试地址

//c.test.quyixian.com  测试H5
//wxtestapi.quyixian.com  测试H5对应的API

export default {
  //admin------------------------------
  //追溯/获取信息
  getCodeAll: baseUrl + "/Admin/GetInfo",

  //user用户------------------------------
  //获取码与图书信息
  getCode: baseUrl + "/User/NewGetCodeInfo",
  //保存用户信息
  SaveInfo: baseUrl + "/User/SaveUserCode",
  //保存盗版信息
  SavePirate: baseUrl + "/User/SavePirateRecord",

  //Wx----------------------------------------
  //获取微信配置=>assets>wechat.js
  //获取微信用户基础信息
  wxInfo: baseUrl + "/WxReg/GetWxInfo",
  //微信公众号授权登录
  wxLogin: baseUrl + "/WxReg/WxLogin",
  //获取地理位置
  wxAddress: baseUrl + "/Wx/GetAddress",
  //发送红包
  wxRedPack: baseUrl + "/Wx/SendRedPack",

  //上传OSS--------------------------------------
  //保存图片OSS
  saveFile: baseUrl + "/File/UploadFileOSS",

  //Lottery抽奖-----------------------------------
  //保存抽奖
  saveLottery: baseUrl + "/Lottery/ChouJiang",
  //获取抽奖
  getLottery: baseUrl + "/Lottery/GetPrizeDetails",
  //获取抽奖列表
  getLotteryList: baseUrl + "/Lottery/GetPrizeList",
  //保存收货地址
  saveAddress: baseUrl + "/Lottery/SetAddress",

  //sign签到---------------------------------------
  //签到
  doSign: baseUrl + "/SignIn/DoSign",
  //获取签单信息
  getSignInfo: baseUrl + "/SignIn/GetSignInfo",

  //Integral积分---------------------------------------
  //获取积分商品列表
  getIntegralList: baseUrl + "/Integral/GetIntegralGoodsList",
  //获取积分兑换记录列表
  getIntegralRecordList: baseUrl + "/Integral/GetIntegralRecordList",
  //获取积分出入记录列表
  getIntegralDetailList: baseUrl + "/Integral/GetUserIntegralDetailList",
};
